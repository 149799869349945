/*=========================================================================================
  File Name: moduleExtraServiceMutations.js
  Description: ExtraService Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.ExtraServices.unshift(item)
  },
  SET_ExtraService(state, ExtraServices) {
    state.ExtraServices = ExtraServices
  },
  UPDATE_ExtraService(state, ExtraService) {
    const ExtraServiceIndex = state.ExtraServices.findIndex((p) => p.ID == ExtraService.ID)
    Object.assign(state.ExtraServices[ExtraServiceIndex], ExtraService)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.ExtraServices.findIndex((p) => p.ID == itemId)
    state.ExtraServices.splice(ItemIndex, 1)
},
}
